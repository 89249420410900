<template>
    <main>
        <div class="px-4 py-5 my-5 text-center d-flex flex-column align-items-center">
            <img src="https://jovsky.ro/wp-content/uploads/2018/11/jovsky-white-logo.png" class="logo">

            <TransitionGroup name="t-page">
                <form class="block p-4 rounded text-start" v-if="step == 1" key="1" @submit.prevent>
                    <h3 class="display-7 fw-bold">Pasul 1: Informații personale</h3>

                    <div class="mb-3">
                        <label for="nume" class="form-label">Nume</label>
                        <input type="text" class="form-control" id="nume" required v-model="form.nume">
                    </div>

                    <div class="mb-3">
                        <label for="prenume" class="form-label">Prenume</label>
                        <input type="text" class="form-control" id="prenume" required v-model="form.prenume">
                    </div>

                    <div class="mb-3">
                        <label for="telefon" class="form-label">Telefon</label>
                        <input type="text" class="form-control" id="telefon" required v-model="form.telefon">
                    </div>

                    <div class="d-flex gap-2 d-sm-flex justify-content-start">
                        <button type="submit" class="btn btn-primary px-4" @click="step++">Următorul pas</button>
                    </div>
                </form>

                <form class="block p-4 rounded text-start" v-if="step == 2" key="2" @submit.prevent>
                    <h3 class="display-7 fw-bold">Pasul 2: Servicii</h3>

                    <div class="mb-3">
                        <label for="servicii" class="form-label">Solicit următoarele servicii (selectie multipla):</label>

                        <div class="form-check" v-for="(serviciu,k) in serviciiOpt" :key="k">
                            <input class="form-check-input" type="checkbox" :value="serviciu.toLowerCase()" :id="`input2${k}`" name="servicii" required v-model="form.servicii">
                            <label class="form-check-label" :for="`input2${k}`">{{ serviciu }}</label>
                        </div>
                    </div>

                    <div class="d-flex gap-2 d-sm-flex justify-content-start">
                        <button type="button" class="btn btn-outline-secondary px-4" @click="step--">Inapoi</button>
                        <button type="submit" class="btn btn-primary px-4" @click="step++">Următorul pas</button>
                    </div>
                </form>

                <form class="block p-4 rounded text-start" v-if="step == 3" key="3" @submit.prevent>
                    <h3 class="display-7 fw-bold">Pasul 3: Sănătate</h3>
                    <div class="mb-3">
                        <label>Ați suferit de:</label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="diabet" id="input31" v-model="form.sanatate">
                            <label class="form-check-label" for="input31">
                                Diabet
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="hipertensiune" id="input32" v-model="form.sanatate">
                            <label class="form-check-label" for="input32">
                                Hipertensiune arterială
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="boli-respiratorii" id="input33" v-model="form.sanatate">
                            <label class="form-check-label" for="input33">
                                Boli respiratorii
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="boala-arteriala" id="input34" v-model="form.sanatate">
                            <label class="form-check-label" for="input34">
                                Boală arterială
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="cancer" id="input35" v-model="form.sanatate">
                            <label class="form-check-label" for="input35">
                                Cancer
                            </label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label>Ați avut vreodată:</label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="infarct-miocardic" id="input36" v-model="form.istoric">
                            <label class="form-check-label" for="input36">
                                Infarct miocardic
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="accident-vascular-cerebral" id="input37" v-model="form.istoric">
                            <label class="form-check-label" for="input37">
                                Accident vascular cerebral
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="astm-bronsic" id="input38" v-model="form.istoric">
                            <label class="form-check-label" for="input38">
                                Astm bronsic
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="sarcina" id="input39" v-model="form.istoric">
                            <label class="form-check-label" for="input39">
                                Sarcină
                            </label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label>Suferiți de:</label>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="alergii" id="input310">
                            <label class="form-check-label" for="input310">
                                Alergii
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="insomnii" id="input311">
                            <label class="form-check-label" for="input311">
                                Insomnii
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="migrene" id="input312">
                            <label class="form-check-label" for="input312">
                                Migrene
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="afectiuni-tiroidiene" id="input313">
                            <label class="form-check-label" for="input313">
                                Afecțiuni tiroidiene
                            </label>
                        </div>
                    </div>

                    <div class="d-flex gap-2 d-sm-flex justify-content-start">
                        <button type="button" class="btn btn-outline-secondary px-4" @click="step--">Inapoi</button>
                        <button type="submit" class="btn btn-primary px-4" @click="step++">Următorul pas</button>
                    </div>
                </form>

                <form class="block p-4 rounded text-start" v-if="step == 4" key="4" @submit.prevent>
                    <h3 class="display-7 fw-bold">Pasul 4: Alte informații</h3>
                    <div class="mb-3">
                        <label>Vă aflați în una din situațiile următoare:</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="angajat" id="input41" name="situatie" required v-model="form.situatie">
                            <label class="form-check-label" for="input41">
                                Angajat
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="student" id="input42" name="situatie" required v-model="form.situatie">
                            <label class="form-check-label" for="input42">
                                Student
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="pensionar" id="input43" name="situatie" required v-model="form.situatie">
                            <label class="form-check-label" for="input43">
                                Pensionar
                            </label>
                        </div>
                    </div>

                    <div class="mb-3">
                        <label>Vă știți alergic la:</label>

                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="niciodata" id="input44" name="alergie" required v-model="form.alergie">
                            <label class="form-check-label" for="input44">
                                Nu am alergii
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="medicamente" id="input45" name="alergie" required v-model="form.alergie">
                            <label class="form-check-label" for="input45">
                                Medicamente
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="cosmetice" id="input46" name="alergie" required v-model="form.alergie">
                            <label class="form-check-label" for="input46">
                                Cosmetice
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="altele" id="input47" name="alergie" required v-model="form.alergie">
                            <label class="form-check-label" for="input47">
                                Altele
                            </label>
                        </div>

                        <textarea class="form-control" v-if="form.alergie == 'altele'" required v-model="form.alergie_custom"></textarea>
                    </div>

                    <div class="d-flex gap-2 d-sm-flex justify-content-start">
                        <button type="button" class="btn btn-outline-secondary px-4" @click="step--">Inapoi</button>
                        <button type="submit" class="btn btn-primary px-4" @click="step++">Următorul pas</button>
                    </div>
                </form>

                <form class="block p-4 rounded text-start" v-if="step == 5" key="5" @submit.prevent>
                    <div class="terms-container mb-3" :class="{'notread': !termsBottom}" @scroll="checkBottom">
                        <Terms/>
                    </div>

                    <div class="d-flex gap-2 d-sm-flex justify-content-start">
                        <button type="button" class="btn btn-outline-secondary px-4" @click="step--">Inapoi</button>
                        <button type="submit" class="btn btn-primary px-4" v-show="termsBottom" @click="step++">Sunt de acord</button>
                    </div>
                </form>

                <form class="block p-4 rounded text-start" v-if="step == 6" key="6" @submit.prevent>
                    <h3 class="display-7 fw-bold">Pasul 5: Semnătură electronică</h3>
                    <div class="mb-3" style="position: relative">
                        <label>Trasați semnătura dumneavoastră:</label><br>
                        <canvas id="signature"></canvas>
                        <div class="btn btn-sm btn-secondary btn-clear-signature" @click="() => { if (signaturePad) signaturePad.clear()}">STERGE</div>
                    </div>

                    <div class="d-flex gap-2 d-sm-flex justify-content-start">
                        <button type="button" class="btn btn-outline-secondary px-4" @click="step--">Inapoi</button>
                        <button type="submit" class="btn btn-primary px-4" @click="step++">Finalizeaza</button>
                    </div>
                </form>

                <form class="block noborder p-4 rounded text-center" v-if="step == 7" key="7">
                    <Transition name="t-page">
                        <div class="" v-if="loading">
                            LOADING ...
                        </div>
                        <div class="" v-else>
                            <div v-if="error">
                                <h2>EROARE!</h2>
                                <div class="alert alert-danger">{{ error }}</div>
                                <div class="btn btn-primary" @click="reset()">OK</div>
                            </div>
                            <div v-else>
                                <h2>SUCCESS</h2>
                                <div class="alert alert-success">Formularul a fost inregistrat cu succes.</div>
                                <div class="btn btn-success" @click="reset()">OK</div>
                            </div>
                        </div>
                    </Transition>            
                </form>
            </TransitionGroup>
        </div>

        
    </main>
</template>

<script setup>
    import SignaturePad from 'signature_pad'

    const { $config } = useNuxtApp()

    const step = ref(1)
    const error = ref(null)
    const loading = ref(false)

    const serviciiOpt = [
        'Tuns top stylist',
        'Spalat',
        'Coafat top stylist',
        'Coafat special',
        'Extensii',
        'Servicii colorare',
        'Tratamente Kerasate',
        'Tratamente Sweet',
        'Tratamente Innovatis',
        'Tuns Valentin & Adrian',
        'Manichiura',
        'Pedichiura',
        'Pensat'
    ]

    const form = ref({
        db: ref(`formular1`),
        nume: ref(null),
        prenume: ref(null),
        telefon: ref(null),

        servicii: ref([]),
        sanatate: ref([]),
        istoric: ref([]),
        afectiuni: ref([]),

        situatie: ref(null),
        alergie: ref(null),
        semnatura: ref(null),
        terms: ref(false),
    })

    
    let signaturePad

    function reset(){
        step.value = 1;
        loading.value = false;
        error.value = null;
        termsBottom.value = false;

        form.value = {
            db: ref(`formular1`),
            nume: ref(null),
            prenume: ref(null),
            telefon: ref(null),

            servicii: ref([]),
            sanatate: ref([]),
            istoric: ref([]),
            afectiuni: ref([]),

            situatie: ref(null),
            alergie: ref(null),
            semnatura: ref(null)
        }
    }
    
    onMounted(() => {
 
    })
    
    const termsBottom = ref(false) 
    function checkBottom(ev){
        termsBottom.value = ev.target.scrollTop >= ev.target.scrollHeight - ev.target.offsetHeight
    }

	watch(() => step.value, async (current, prev) => {
        if (prev == 1 && current == 2){
            if (!form.value.nume || !form.value.prenume || !form.value.telefon){
                step.value = 1;
            }
        }

        if (prev == 2 && current == 3){
            if (!form.value.servicii.length){
                step.value = 2;
            }            
        }

        if (prev == 3 && current == 4){
            //
        }     

        if (prev == 4 && current == 5){
            if (!form.value.situatie || !form.value.alergie || (form.value.alergie == 'altele' && !form.value.alergie_custom)){
                step.value = 4;
            }   
        } 

        if (prev == 6 && current == 7){
            if (signaturePad.isEmpty()){
                step.value = 6
                alert("Semnatura este obligatorie !")
            }else{
                form.value.semnatura = signaturePad.toDataURL();
            }          
        }

		if (current == 6){
            setTimeout(() => {
                const canvas = document.querySelector("canvas");
                signaturePad = new SignaturePad(canvas);

                const ratio = Math.max(window.devicePixelRatio || 1, 1);
                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                canvas.getContext("2d").scale(ratio, ratio);
                signaturePad.clear(); // otherwise isEmpty() might return incorrect value

            }, 100)                     
        }else{
            if (signaturePad) signaturePad.off()
        }

        if (step.value == 7){
            loading.value = true
            const data = JSON.parse( JSON.stringify(form.value));

            const form_data = new FormData();
            for ( var key in data ) {
                form_data.append(key, data[key]);
            }

			const response = await $fetch(`https://customweb.ro/jovsky/submit.php`, {
				method: "POST",
				body: form_data
			})

            loading.value = false

            if (response){
                console.log("DB ERROR", response)
                error.value = `Unexpected Database Error, ${response}`
            }
        }
	}, { immediate: true })
</script>
