<template>
    <article id="post-3" class="clearfix blog-post post-3 page type-page status-publish hentry">
        <div class="spacing10 clearfix"></div>

        <h1 class="blog-title">Politica de Confidentialitate Jovsky Studio</h1>
        <div class="spacing40 clearfix"></div>

        <p>Ne pasa de datele tale cu caracter personal!</p>
        <p>De aceea, Politica de Confidentialitate Jovsky Studio descrie in detaliu gestionarea si utilizarea datelor furnizate sau colectate pe platformele noastre digitale.</p>
        <p>Politica de confidentialitate a Jovsky Studio respecta Regulamentul General UE privind Protectia Datelor cu Caracter Personal 2016/679 („GDPR”), precum si legislatia locala.</p>
        <p>Compania noastra, SC JOV ART STUDIO SRL, cu sediul social in Bucuresti, Sector 1, Virgil Madgearu, nr. 27, nr. Inregistrare J40/4025/2016, cod fiscal RO35818976, este operator autorizat de prelucrare a datelor cu caracter personal, conform prevederilor legale europene, certificand respectarea drepturilor conferite de GDPR privind prelucrarea datelor cu caracter personal.</p>
        <p>Jovsky Studio este raspunzatoare pentru colectarea, stocarea si procesarea datelor tale personale si isi ia angajamentul de a respecta confidentialitatea tuturor informatiilor stocate.</p>
        <ol>
            <li><strong> BAZA LEGALA PENTRU PROCESAREA DATELOR CU CARACTER PERSONAL</strong></li>
        </ol>
        <p><strong><em>Prelucrarile datelor tale cu caracter personal pot avea ca temei:</em></strong></p>
        <ul>
            <li>Prelucrarea rezervarilor pe care tu le faci pe site-ul nostru, in vederea beneficierii de servicii din partea echipei noastre de profesionisti, inclusiv programare, facturare, transmitere de informatii utile referitoare la serviciile pe care le prestam sau solutionarea diferitelor cerinte sau neconformitati aparute;</li>
            <li>Rezolvarea eventualelor cerinte transmise de tine prin intermediul formularelor noastre de contact de pe site;</li>
            <li>Interesul legitim al Operatorului (Jovsky Studio) in ceea ce priveste realizarea si distribuirea chestionarelor de satisfactie adresate clietilor;</li>
            <li>Consintamantul persoanei vizate (transmiterea de comunicari de marketing, oferte privind produse / servicii noi, newsletter, brosuri, informatii campanii, etc.)</li>
            <li>Obligatia legala a Operatorului (apararea unui drept in instanta, etc.)</li>
        </ul>
        <p>In consecinta, baza legala pentru procesarea datelor cu caracter personal este reprezentata de <strong>acordul</strong> tau, acord pe care il poti retrage in orice moment, dupa cum este mentionat in prezentul document, acest lucru neafectand legitimitatea procesarii datelor de catre Jovsky Studio, inainte de retragerea acordului.</p>
        <p>In eventualitatea in care dezvaluim date cu caracter personal organelor de aplicare a legii sau altor organisme guvernamentale, vom efectua acest lucru in baza faptului ca avem obligatia legala de a o face.</p>
        <p>&nbsp;</p>
        <ol start="2">
            <li><strong> CATEGORII DE DATE CU CARACTER PERSONAL SI SCOPUL COLECTARII ACESTORA</strong></li>
        </ol>
        <p>Jovsky Studio aplica principiul minimizarii si se angajeaza sa colecteze doar acele date care sunt cu adevarat necesare. Astfel, te informam ca datele personale pe care Jovsky Studio le prelucreaza sunt date persoanale pe care tu (persoana vizata) ni le pui la dispozitie, cum ar fi:</p>
        <ul>
            <li>Date de identificare, cum ar fi: numele si prenumele, adresa de domiciliu / resedinta, cod numeric personal, cod unic de inregistrare, numar de telefon fix/mobil, serie si numar act de identitate, adresa de e-mail.</li>
            <li>Informatii financiare, precum cont bancar, card bancar, etc.</li>
        </ul>
        <p><em>Aceste date sunt necesare pentru activitati precum:</em></p>
        <ul>
            <li>Prestarea efectiva a serviciilor noastre, solicitate prin intermediul programarii online sau telefonice realizate de tine;</li>
            <li>Solutionarea problemelor de orice natura referitoare la produsele si serviciile Jovsky Studio;</li>
            <li>Asigurarea serviciilor de suport si consultanta, prin acordarea de raspunsuri la intrebarile tale cu privire la serviciile companiei, precum si solutionarea reclamatiilor, remedierea eventualelor neconformitati, incasari si plati in conturi bancare.</li>
        </ul>
        <p><strong>Refuzul tau de a ne furniza aceste date ne va pune in imposibilitatea de a-ti pune la dispozitie produsele si / sau serviciile dorite.</strong></p>
        <p>Jovsky Studio colecteza urmatoarele categorii principale de date cu caracter personal, in vederea atingerii urmatoarelor scopuri:</p>
        <p><em><u>2.1 Operatiuni generale Jovsky Studio</u></em></p>
        <p>Colectam date cu caracter personal ale persoanelor fizice, partenerilor, clientilor, furnizorilor (incusiv ale unor terte parti, furnizoare de servicii) si ale altor parti. Aceste date pot include detalii de contact, nume si prenume, informatii financiare si alte date mai sus mentionate, necesare pentru efectuarea operatiunilor de colaborare cu tine sau cu organizatia pe care o reprezinti.</p>
        <p><em><u>2.2 Raspuns la solicitarile tale</u></em></p>
        <p>Poti decide sa ne furnizezi date cu caracter personal, inclusiv numele tau, adresa de e-mail si alte informatii de contact, atunci cand ne contezi prin telefon, e-mail, posta sau folosind platforma noastra digitala. Aceste informatii ne permit sa raspundem la solicitarile tale, privind serviciile Jovsky Studio</p>
        <p><em><u>2.3 Clienti si potentiali clienti</u></em></p>
        <p>Putem colecta datele cu caracter personal ale clientilor si ale potentialilor clienti, inclusiv informatii de contact, informatii financiare (carduri de credit, conturi bancare) si alte informatii care ne sunt necesare pentru efectuarea operatiunilor cu respectiva persoana fizica sau organizatie. Aceste informatii pot fi transmise catre distribuitori si parteneri de logistica in vederea acordarii de raspuns la solicitarile acestora.</p>
        <p><em><u>2.4 Dezvoltarea afacerii</u></em></p>
        <p>Datele cu caracter personal furnizate de catre tine, precum si informatiile pe care le colectam prin intermediul platformelor noastre digitale vor fi folosite pentru a creste nivelul de intelegere si cunoastere a clientilor nostri si pentru a asigura o comunicare relevanta sub toate aspectele relatiei dintre tine si Jovsky Studio. Datele cu caracter personal pot fi folosite si pentru dezvoltarea de noi produse si servicii sau pentru imbunatatirea celor deja existente.</p>
        <p><em><u>2.5 Comunicarea de marketing</u></em></p>
        <p>Putem folosi datele cu caracter personal doar cu acordul si la solicitarea ta, pentru a te tine la curent cu privire la cele mai noi oferte pentru produsele si serviciile care te intereseaza. In acest sens iti putem trimite mesaje informative prin intermediul a diverse mijloace (e-mail, telefon, SMS, mobile push, webpush) continand atat informatii generale, cat si specifice in legatura cu o anumita tematica, informatii cu privire la servicii similare sau complementare cu cele achizitionate de tine, informatii cu privire la promotii si oferte la produsele si serviciile Jovsky Studio. Iti putem transmite de asemenea si comunicari de natura comerciala, precum cercetari de piata relevante sau sondaje de opinie. Jovsky Studio se asigura ca prelucrarea datelor cu caracter personal in scopul comunicarii de marketing este efectuata avand la baza respectarea drepturilor si libertatilor tale si ca deciziile luate in baza acestora nu au efecte legale asupra ta si nu te afecteaza in mod similar intr-o masura semnificativa.</p>
        <p><em><u>2.6 Sondaje ale clientilor si vizitatorilor</u></em></p>
        <p>Jovsky Studio poate colecta date cu caracter personal de la vizitatorii platformelor noastre digitale sau de la clienti, in scopul prelucrarii acestora in cadrul desfasurarii a diverse sondaje si cercetari cu privire la produsele si serviciile noastre. Datele tale personale nu vor fi utilizate pentru comunicari de marketing, fara acordul tau.</p>
        <p><em><u>2.7 Potentiali angajati</u></em></p>
        <p>Daca o persoana se inscrie pentru ocuparea unei pozitii in cadrul Jovsky Studio sau incheie un contract cu noi, putem colecta anumite date cu caracter personal, cum ar fi nume si prenume, informatii de contact, informatii referitoare la activitatea si interesele profesionale, diplome de studii detinute. Aceste informatii vor fi colectate fie in mod direct de la persoana in cauza, fie de la un consultant de recrutari, angajatori anteriori, referinte sau diverse surse facute publice. Vom utiliza aceste informatii in scopul luarii unei decizii referitoare la a transmite o oferta de angajare sau la incheierea unui contract cu persoana respectiva.</p>
        <p><em><u>2.8 In vederea respectarii prevederilor legale</u></em></p>
        <p>Putem colecta date cu caracter personal conform cerintelor sau permisiunilor legislatiei in vigoare. Atat datele cu caracter personal, cat si cele cu caracter nepersonal colectate de Jovsky Studio, sunt considerate a fi confidentiale si nu vor fi niciodata vandute sau cedate unor terte parti, cu exceptia cazurilor mentionate in sectiunea 2.2 si 2.3.</p>
        <p><strong>&nbsp;</strong></p>
        <ol start="3">
            <li><strong> CUM COLECTAM DATELE CU CARACTER PERSONAL </strong></li>
        </ol>
        <p>Jovsky Studio colecteaza datele cu caracter personal pe care tu le furnizezi atunci cand soliciti produse sau informatii de la noi, cand efectuezi comenzi sau initiezi solicitari catre noi, cand raspunzi la chestionare sau cand interactionezi in orice alt mod cu noi.</p>
        <p>Colectam informatii despre tine prin intermediul a diverse tehnologii, precum cookie-uri. In vederea usurarii navigarii pe website, de fiecare data cand tu incarci adresa web Jovsky Studio, un sistem definit de cookie-uri te poate ajuta prin recunoasterea numelui si a adresei de e-mail. Aceasta actiune este posibila doar in cazul concret in care cookie-urile (cookie – fragmente de informatii stocate de un site, de pe un computer) sunt activate. Daca alegi sa nu primesti cookie-uri, poti dezactiva optiunea din meniul browser-ului tau de internet. Te invitam sa verifici politica noastra de cookie-uri.</p>
        <ol start="4">
            <li><strong> LINKURI CATRE ALTE PAGINI WEB</strong></li>
        </ol>
        <p>Aceasta pagina de internet contine linkuri catre alte pagini de internet (cum ar fi Facebook, Intagram), carora nu li se aplica prezenta Politica de Confidentialitate. Te rugam sa tii cont de faptul ca nu se asumam alte pagini de internet si continutul acestora. Te incurajam sa citesti politica de confidentialitate a fiecarei pagini de internet pe care o vizitezi.</p>
        <p>&nbsp;</p>
        <ol start="5">
            <li><strong> TRANSMITEREA DATELOR TALE CATRE ALTE COMPANII</strong></li>
        </ol>
        <p>Nu vom transmite datele tale personale catre alte companii decat cu exceptia anumitor situatii, in care se includ:</p>
        <ul>
            <li>Daca parti ale grupului sau terte parti asigura servicii pentru noi, de exemplu livrarea de colete si relatii cu clientii. Acestor companii li se interzice sa foloseasca datele tale personale pentru alte scopuri decat cele solicitate de catre noi prin lege.</li>
            <li>Daca transmitem informatiile tale unor companii partenere sau unor terte parti pentru a asigura siguranta clientilor nostri, pentru a ne proteja drepturile si proprietatea noastra, pentru a respecta procedurile legale, sau in alte cazuri, in situatia in care consideram, cu buna credinta ca dezvaluirea informatiilor respective este solicitata prin lege.</li>
            <li>Daca ne permiti sa transmitem informatiile tale personale cu terte parti, cum ar fi:</li>
            <li>Decizi sa transmiti datele tale personale unor companii atent selectate pentru ca acestea sa iti poata trimite oferte si promotii referitoare la produsele si serviciile lor;</li>
            <li>Ne indrumi sa transmitem datele tale personale unor platforme sau pagini de internet ale unor terte parti, cum ar fi paginile de socializare;</li>
        </ul>
        <ol start="6">
            <li>
                <strong> RECIPIENTI DE PRELUCRARE A DATELOR IN MEDIUL ONLINE</strong>
            </li>
        </ol>
        <p><strong><em>Google Analytics</em></strong></p>
        <p>Google Analytics este un serviciu de analiza online oferit de Google, care ne permite sa urmarim si sa analizam traficul de pe website. Acest program este folosit in contextul Inbound Marketing si ne ajuta cu analize si evaluari statistice ale comportamentului utilizatorului logat, pentru a putea sa coordonam mai bine strategia noastra de marketing si sa optimizam continutul pe care ti-l oferim. Pentru mai multe informatii, te rugam sa verifici informatiile despre Confidentialitatea si Securitatea Datelor ale Google Analytics, accesand <strong><a href="https://policies.google.com/privacy?hl=ro">https://policies.google.com/privacy?hl=ro</a></strong><strong>.</strong></p>
        <p>&nbsp;</p>
        <ol start="7">
            <li><strong> CE DREPTURI AI?</strong></li>
        </ol>
        <p>Potrivit legislatiei in vigoare, tu (persoana vizata) ai drepturi care iti permit exercitarea unei anumite masuri de control si decizii in ceea ce priveste colectarea, prelucrarea, utilizarea si stocarea datelor tale personale.</p>
        <p><em>In conformitate cu legislatia in vigoare, drepturile tale cuprind:</em></p>
        <ol>
            <li>a) dreptul de a fi informat cu privire la Datele Personale prelucrate de Operator;</li>
            <li>b) dreptul de a obtine din partea Operatorului confirmarea faptului ca acesta prelucreaza Datele Personale ale persoanei vizate si, in caz afirmativ, acces la datele respective si la informatii precum scopurile prelucrarii, categoriile de date personale vizate, destinatarii sau categoriile de destinatari, acolo unde este posibil, perioada de stocare preconizata. In cazul in care Datele Personale nu sunt colectate direct de la persoana vizata, sursa acestor date precum si, acolo unde este cazul, existenta unui proces decizional automatizat incluzand crearea de profiluri;</li>
            <li>c) dreptul la rectificarea Datelor Personale inexacte sau completarea acestora;</li>
            <li>d) dreptul la stergerea Datelor Personale, in conformitate cu prevederile legale aplicabile in domeniul protectiei datelor cu caracter personal;</li>
            <li>e) dreptul la restrictionarea prelucrarii atunci cand se aplica unul din urmatoarele cazuri: persoana vizata contesta exactitatea datelor, prelucrarea este ilegala, persoana vizata se opune stergerii datelor, atunci cand Operatorul nu mai are nevoie de Datele Personale in scopul prelucrarii, dar persoana vizata le solicita pentru constatarea, exercitarea sau apararea unui drept in instanta sau atunci cand persoana vizata se opune prelucrarii, pentru intervalul de timp in care se verifica daca interesele legitime ale Operatorului prevaleaza asupra celor ale Persoanei vizate;</li>
            <li>f) dreptul la portabilitatea datelor, care consta in posibilitatea de a solicita Operatorului transmiterea Datelor Personale furnizate de persoana vizata, intr-un format structurat utilizat in mod curent si care poate fi citit automat, si transmiterea de catre persoana vizata a acestor date catre un alt operator;</li>
            <li>g) dreptul de opozitie la prelucrarea Datelor Personale, in orice moment, in mod gratuit si fara nicio justificare pentru situatii precum:
                <ul>
                    <li>primirea de comunicari comerciale;</li>
                    <li>adoptarea unei decizii automate, inclusiv crearea de profiluri;</li>
                    <li>desfasurarea de activitati de prelucrare necesare in vederea indeplinirii unui interes legitim al Operatorului.</li>
                </ul>
            </li>
        </ol>
        <p><em>* In caz de opozitie nejustificata, Operatorul este indreptatit sa prelucreze in continuare Datele Personale.</em></p>
        <ol>
            <li>h) posibilitatea persoanei vizate de a solicita Operatorului de a nu face obiectul unei decizii bazate exclusiv pe o prelucrare automata, inclusiv crearea de profiluri, si care produce efecte juridice care sa priveasca persoana vizata sau care sa o afecteze intr-o masura semnificativa. Cu privire la adoptarea unei decizii bazate exclusiv pe o prelucrare automata, persoana vizata are posibilitatea de a isi exprima punctul de vedere, de a solicita interventia unui operator uman, precum si posibilitatea de a contesta o astfel de decizie, prin modalitatile descrise in prezenta informare;</li>
            <li>i) dreptul de a depune o plangere in fata Autoritatii Nationale pentru Supravegherea Prelucrarii Datelor cu Caracter Personal (ANSPDCP);</li>
            <li>j) dreptul de a se adresa justitiei.</li>
        </ol>
        <p>Pentru exercitarea drepturilor prevazute la literele a) – j) de mai sus, puteti inainta catre Operator o cerere scrisa, datata si semnata, sau puteti transmite o astfel de cerere pe e-mail, catre responsabilul cu protectia datelor cu caracter personal, la adresa de e-mail <a href="mailto:dpo@jovsky.ro">dpo@jovsky.ro</a>. Raspunsul la solicitarile tale in ceea ce priveste exercitarea oricaror drepturi prezentate mai sus se va face in maxim 30 zile de la primire.</p>
        <p>&nbsp;</p>
        <ol start="8">
            <li><strong> SECURITATEA, INTEGRITATEA SI CONFIDENTIALITATEA DATELOR</strong></li>
        </ol>
        <p>Securitatea, integritatea si confidentialitatea datelor tale personale sunt deosebit de importante pentru noi. Am implementat masuri tehnice, administrative si de securitate fizica ce sunt menite sa protejeze datele tale personale de acces, dezvaluire, utilizare si modificare neautorizate. La anumite intervale, ne revizuim procedurile de securitate pentru a include tehnologii si metode adecvate de data recenta.</p>
        <p>Cu toate acestea, nici un mecanism informatic nu ofera securitate totala, un element de risc find intotdeauna prezent, risc care este independent de vointa si / sau posibilitatile noastre. Securitatea acestui site poate fi supusa unor vulnerabilitati si, ca atare, pentru astfel de situatii, nu putem fi considerati responsabili pentru nici o incalcare a securitatii.</p>
        <p>&nbsp;</p>
        <ol start="9">
            <li><strong> PERIOADA DE STOCARE A DATELOR CU CARACTER PERSONAL</strong></li>
        </ol>
        <p>Vom retine datele tale personale atata timp cat este necesar pentru indeplinirea scopurilor mentionate in prezenta Politica de Confidentialitate Jovsky Studio, cu exceptia cazului in care o perioada mai indelungata de retinere este ceruta sau permisa prin lege. In mod regulat, vom improspata informatiile pentru a ne asigura ca acestea sunt actuale.</p>
        <p>Datele Personale colectate in vederea transmiterii de comunicari comerciale vor fi prelucrate pana la data retragerii consimtamantului exprimat de persoana vizata.</p>
        <p>&nbsp;</p>
        <ol start="10">
            <li><strong> MODIFICARI IN POLITICA DE CONFIDENTIALITATE JOVSKY STUDIO</strong></li>
        </ol>
        <p>Ocazional, putem modifica prezenta Politica de Confidentialitate pentru a cuprinde cele mai recente tehnologii, practici din industrie, cerinte de reglementare sau pentru alte scopuri. Intotdeauna vom posta cea mai recenta versiune pe platformele noastre digitale. De aceea te sfatuim sa citesti cu regularitate Politica de Confidentialitate. In situatiile in care suntem obligati prin lege, vom solicita acordul tau.</p>
        <p>Jovsky Studio isi rezerva dreptul de a modifica aceasta Politica de Confidentialitate in orice moment, insa in limitele prevazute de prevederile legale, interne si comunitare, in vigoare.</p>
        <p>Aceasta declaratie de confidentialitate si politicile de confidentialitate prezentate aici nu au scopul de a creea drepturi legale contractuale sau de alta natura sau in numele oricarei parti.</p>
        <p>&nbsp;</p>
        <ol start="11">
            <li><strong> OBSERVATII SI INTREBARI</strong></li>
        </ol>
        <p>Daca ai observatii sau intrebari referitor la Politica de Confidentialitate Jovsky Studio, te rugam sa ne contactezi la telefon 0766.568.759, 0756.032.322 sau 0748.878.008.</p>
        <p>De asemenea, te informam ca poti depune o plangere referitoare la felul in care procesam datele tale personale. In cazul in care se face o plangere, numele si informatiile de contact ale reclamantului trebuie comunicate catre Jovsky Studio. Vom investiga reclamatia si vom raspunde in termen de maxim 30 de zile. In cazul in care consideri ca nu am rezolvat in mod satisfacator reclamatia ta, poti depune o plangere la Autoritatea Nationala de Supraveghere a Prelucrarii Datelor cu Caracter Personal (<a href="http://www.dataprotection.ro">www.dataprotection.ro</a>).</p>
        <p>&nbsp;</p>
        <ol start="12">
            <li><strong> INFORMATII SUPLIMENTARE</strong></li>
        </ol>
        <p>Aceasta pagina de internet este detinuta si operata de catre SC JOV ART STUDIO SRL.</p>
        <p>Prezenta Politica de Confidentialitate a fost actualizata ultima data in luna februarie 2019.</p>

        <div class="spacing40 clearfix"></div>



        <div class="post-pager clearfix">


        </div>

        <div class="border-post clearfix"></div>

        <div class="clearboth spacing40"></div>



    </article><!--/.blog-post-->
</template>